
import * as React from 'react';
import Box from '@mui/material/Box';


import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import AssistantIcon from '@mui/icons-material/Assistant';


import { initializeApp } from "firebase/app";
import { getVertexAI, getGenerativeModel } from "firebase/vertexai";
import loadOrariMesseInfo from './utils'
import { AIHistory } from './AIHistory'
//import { AISystemInstruction } from './AISystemInstruction'


  //const apiKey = "AIzaSyB4FSYZY1rSMSZRKcmUfKtfgI6KFDKyqhU"

  const firebaseConfig = {
    apiKey: "AIzaSyBfJ1SQnyzAufqR4ZNxSgXE_j3CW22amXA",
    authDomain: "casadelvoltosanto.firebaseapp.com",
    projectId: "casadelvoltosanto",
    storageBucket: "casadelvoltosanto.appspot.com",
    messagingSenderId: "280575177469",
    appId: "1:280575177469:web:e6c8356a5391d67dd58494"
  };

  // Initialize FirebaseApp
  const firebaseApp = initializeApp(firebaseConfig);

  // Initialize the Vertex AI service
  const vertexAI = getVertexAI(firebaseApp);

  async function makeApiRequest(date) {
    return loadOrariMesseInfo(date);
  }


  // Function declaration, to pass to the model.
  const getOrariMesseDeclaration = {
    name: "getOrariMesseInfo",
    parameters: {
      type: "OBJECT",
      description: "Presa una data nel formato yyyy-mm-dd ritorna gli orari delle messe e delle celebrazioni religiose",
      properties: {
        date: {
          type: "string",
          description: "Data del giorno nel formato yyyy-mm-dd",
        }
      },
      required: ["date"]
    },
  };

  // Executable function code. Put it in a map keyed by the function name
  // so that you can call it once you get the name string from the model.
  const functions = {
    getOrariMesseInfo: ( date ) => {
      console.log("sto dentro functions!!!!")
      return makeApiRequest( date )
    }
  };


  /*
 * Install the Generative AI SDK
 *
 * $ npm install @google/generative-ai
 *
 * See the getting started guide for more information
 * https://ai.google.dev/gemini-api/docs/get-started/node
 */

  
  const model = getGenerativeModel(vertexAI, {
    model: "gemini-1.5-pro-preview-0514",
    //systemInstruction: AISystemInstruction,
    tools: {
      functionDeclarations: [getOrariMesseDeclaration],
    },
  });
  
  /*const generationConfig = {
    temperature: 1,
    topP: 0.95,
    topK: 64,
    maxOutputTokens: 8192,
    responseMimeType: "text/plain",
  };*/
  
  let chatSession = null;

  var history = AIHistory;
  history.push(    {
    role: "user",
    parts: [
      {text: "la data di oggi è " + new Date().toISOString().slice(0,10) + "\n"},
    ],
  },
  {
    role: "model",
    parts: [
      {text: "ok\n"},
    ],
  })


  async function initAI() {
    chatSession = model.startChat({
      //generationConfig,
   // safetySettings: Adjust safety settings
   // See https://ai.google.dev/gemini-api/docs/safety-settings
      
      history: history
    });
  }
  
  

  const sendAIMessage = async (message) => {
    console.log("Avvio chat AI")
    //console.log("Messaggio:" + message)
    //g = "quali sono gli orari delle messe di domenica 14 luglio 2024"
    /*const result = await chatSession.sendMessageStream(test_msg);

    let text = '';
    for await (const chunk of result.stream) {
      const chunkText = chunk.text();
      console.log(chunkText);
      text += chunkText;
    }*/


    const result = await chatSession.sendMessage(message);
    // For simplicity, this uses the first function call found.
    const calls = result.response.functionCalls();


    if (calls && calls.length > 0) {
      const call = calls[0];
      // Call the executable function named in the function call
      // with the arguments specified in the function call and
      // let it call the hypothetical API.
      const date_string = call.args.date + 'T00:00:00'
      console.log(date_string)
      const d = new Date(date_string);
      console.log(d)
      const apiResponse = await functions[call.name](d);
      console.log(apiResponse)

      // Send the API response back to the model so it can generate
      // a text response that can be displayed to the user.
      const result = await chatSession.sendMessage([{functionResponse: {
        name: 'getOrariMesseInfo',
        response: apiResponse
      }}]);
      console.log(result)
      // Log the text response.
      console.log(result.response.text());
      return result.response.text();
    } else {
      console.log(result.response.text());
      return result.response.text();
    }
  }

  initAI()




export default function Footer () {

    const [aiInput, setAiInput] = React.useState("")
    const [apiResponse, setApiResponse] = React.useState("")
    const [aiResponseVisible, setAiResponseVisible] = React.useState(false)

    const onAIInputChange = (v) => {
      setAiInput(v.target.value)
    }


    const onAIsend = async () => {
      console.log("Invio messaggio AI")
      setAiResponseVisible(true)
      setApiResponse("Sto pensando...")
      const res = await sendAIMessage(aiInput)
      setAiResponseVisible(true)
      setApiResponse(res)
    }


    return(<div>
      <center>Napoli - Via Ponti rossi, 54 - Telefono 0817410746</center>
        <Box
        component="form"
        position="fixed"
        bottom="0px"
        right="0px"

        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >          
        { apiResponse && aiResponseVisible &&
        <Paper id="ai-response-message-body" class="messagesBody">
          <IconButton color="primary" sx={{ p: '5px', float: 'right' }} aria-label="close" onClick={() => setAiResponseVisible(false)}>
            <CloseIcon />
          </IconButton>
          <InputBase
            multiline={true}
            maxRows={10}
            sx={{ ml: 1, flex: 1, maxHeight: '350px', overflowY: 'auto'}}
            placeholder={apiResponse}
            readOnly={true}
            inputProps={{ 'aria-label': '' }}
          />
        </Paper>
        }
        <Paper
          sx={{ p: '2px 4px', border: 1,display: 'flex', alignItems: 'center', width: 460 }}
        >
          <AssistantIcon />
          <InputBase
            multiline={true}
            sx={{ ml: 1, flex: 1 }}
            placeholder="Domande?"
            onChange={onAIInputChange}
            inputProps={{ 'aria-label': 'search google maps' }}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={onAIsend}>
            <SendIcon />
          </IconButton>
        </Paper>
      </Box>
      </div>)
}