import React from "react";
import Container from '@mui/material/Container';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Info = () => {
  return (
    <Container>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5">Benedizioni</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <b>Sabato, Domenica e Giorni festivi</b>: dalle ore 9,00 alle ore 12,30 e dalle 16,30 alle 19,00 <br></br>
            C'è il diacono fuori la chiesa tra una messa e l'altra.
            <br></br><br></br>
            <b>Nei giorni feriali</b> dopo le messe della mattina bisogna chiedere al sacerdote subito dopo la celebrazione.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant="h5">Anniversari</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Per celebrare anniversari di matrimonio in chiesa bisogna prendere il nullaosta della parrocchia dove ci si è sposati e con questo nullaosta venire nella Sala delle Offerte e prenotare per il giorno stabilito.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant="h5">Offerte: Italia</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>

          <em>La Casa del Volto Santo vive solo con il tuo aiuto</em>

          <b>DALL'ITALIA</b>

            
              <b>Conto Corrente:</b>
              Conto corrente postale n. 386805<br></br>
              intestato a: Istituto Piccole Ancelle di Cristo Re - Opera assistenziale e socio educativa –
              Casa del Volto Santo – Via Ponti Rossi 54 – 80131 Napoli<br></br><br></br>
              
              <b>Vaglia Postale:</b>
              Indirizzato a: Istituto Piccole Ancelle di Cristo Re - Opera assistenziale e socio educativa –
              Casa del Volto Santo – Via Ponti Rossi 54 – 80131 Napoli<br></br><br></br>
              
              <b>Assegno non trasferibile:</b>
              (a mezzo rassicurata) intestato all’: Istituto Piccole Ancelle di Cristo Re - Casa del Volto Santo<br></br><br></br>
              
              <b>Bonifico bancario:</b>
              BANCA INTESA SAN PAOLO filiale di Milano<br></br>
              ISTITUTO PICCOLE ANCELLE DI CRISTO RE<br></br>
              IBAN: IT69P0306909606100000110274<br></br>
              BIC: BCITITMM<br></br>
              (precisare il proprio nome e indirizzo nello spazio riservato alla causale del versamento. Se i
              dati nonn sono indicati in tale spazio l’offerta ci perviene come anonima).<br></br><br></br>
              
              <b>Bancoposta on line</b>
              I correntisti del servizio “Bancoposta on line” possono effettuare le offerte direttamente sul
              c.c.p. n. 386805 intestato all’: Istituto Piccole Ancelle di Cristo Re - Opera assistenziale e socio
              educativa – Casa del Volto Santo – Via Ponti Rossi 54 – 80131 Napoli.<br></br>
              <br></br><br></br>
              Destina anche tu il 5x1000
              dell’Irpef all’Istituto Piccole Ancelle di Cristo Re, indica il nostro codice fiscale
              80016730634.<br></br><br></br>


          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant="h5">Offerte: Estero (foreign countries)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>

            <b><b>DALL' ESTERO (foreign countries)</b></b>

                <b>Bonifico bancario:</b>
                BANCA INTESA SAN PAOLO<br></br>
                IBAN: IT69P0306909606100000110274<br></br>
                BIC: BCITITMM<br></br>
                (precisare il proprio nome e indirizzo nello spazio riservato alla causale del versamento. Se i
                dati nonn sono indicati in tale spazio l’offerta ci perviene come anonima).<br></br><br></br>
                
                <b>Western Union:</b> (trasferimento rapido) indicando come persona incaricata di riscuotere l’offerta il
                nome di Suor Minicozzi Esterina.<br></br><br></br>

     
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant="h5">Il 5 per mille</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>

            L'istituto Piccole Ancelle di Cristo Re, che ha come unico scopo l'assistenza agli anziani appartenenti alle classi sociali più povere e all'infanzia derelitta, è stato ammesso tra i destinatari al contributo del cinque per mille, con <b>codice fiscale 80016730634</b>.
            L'Istituto e le Suore Piccole Ancelle sono presenti in Campania(Napoli e Provincia) con dodici case religiose, tra cui la Casa del Volto Santo; nella città di Roma con tre Case Religiose<br></br>
            vengono ospitate presso le loro strutture circa 200 anziani e 300 bambini. Per tutti bisogna provvedere alle loro necessità.<br></br>
            Inoltre, l'Istituto si è aperto fin dal 1993 alle Missioni: in Romania, nelle Filippine e nell'Indonesia, interessandosi alle classi meno abbienti, quelle incapaci di soddisfare i bisogni primi.
            E' a questo scopo che chiediamo il tuo contributo mediante la scelta per la destinazione del cinque per mille, nella dichiarazione dei redditi e, quindi, ti preghiamo di esprimere un tuo sostegno per organizzazioni non lucrative ed attività sociali, indicando il nostro codice fiscale 80016730634.
            Con questa scelta non vi sarà alcuna spesa a tuo carico, ma l'erario provvederà a girare i fondi in favore della istituzione prescelta. L'importo è già stato stanziato e in mancanza della tua indicazione rimarrà inutilizzato o devoluto allo Stato<br></br>
            Nel prometterti la costante ed umile preghiera di tutte le Piccole Ancelle di Cristo Re, Ti assicuriamo che il tuo contributo sarà utilizzato esclusivamente in favore dei nostri poveri. Anche a nome loro ti diciamo grazie.

  
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant="h5">Dove siamo</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>

              <a href="https://www.google.it/maps/place/Casa+Del+Volto+Santo/@40.8684667,14.2544603,17z/data=!3m1!4b1!4m5!3m4!1s0x133b0875ec767845:0xf8c70084dcde8199!8m2!3d40.8684667!4d14.256649" target="_blank" rel="noopener noreferrer">
              <i aria-hidden="true"></i>
              
              <small >La Casa Del Volto Santo è sita in <b>Via Ponti Rossi, 54 - 80131 Napoli</b> Italia</small>	</a>

            <iframe title="Mappa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3017.153521296296!2d14.254339315409737!3d40.8685049793152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x133b0875ec767845%3A0xf8c70084dcde8199!2sCasa%20del%20Volto%20Santo!5e0!3m2!1sit!2sit!4v1629452201215!5m2!1sit!2sit" width="100%" height="450" style={{"border":0}} allowFullScreen="" loading="lazy"></iframe>


            Con l’<b>auto</b>: Tangenziale - Uscita Capodimonte<br></br>
            <b>Filobus</b> 254 dalla <b>Stazione</b> Ferroviaria<br></br>
            <b>Autobus</b> C63 dai Ponti Rossi al <b>Museo</b><br></br> 
            <b>Autobus</b> C66 dall’<b>Ospedale Cardarelli</b><br></br>    
   
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant="h5">Pellegrini</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <b>SALA DEL PELLEGRINO</b>
            <div>
          I pellegrini che si recano presso il nostro Tempio possono usufruire della sala per
          consumare i loro pasti, previa prenotazione. Per prenotare chiamare il numero di telefono che trovate in basso a questa pagina.
            </div>
          <hr></hr>
            <b>MUSEO MADRE FLORA</b>
            <div>
          E' possibile visitare il museo dedicato a Madre Flora previa prenotazione. Per prenotare chiamare il numero di telefono che trovate in basso a questa pagina.
            </div>        
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant="h5">Orari</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <b>ORARI MESSE</b>
            <div>
              <a href="?c=orari_messe"> ORARI MESSE </a>
            </div>
          <hr></hr>
          
            <b>ORARI DI APERTURA</b>
            <div>
              TEMPIO:<br></br>
              Giorni festivi e feriali dalle 7,00 alle 20,00  <br></br><br></br>
              SALA OFFERTE:<br></br>
              Tutti i giorni dalle ore 9,00 alle 14 e dalle 15,30 alle 20,00<br></br>
            </div>

          <hr></hr>


            <b>CONFESSIONI</b>
            <div>
              Giorni feriali: dalle ore 9,00 alle ore 9,30<br></br>
        dalle ore 10,30 alle ore 12,00<br></br>

        dalle ore 16,30 alle ore 18,30     <br></br><br></br>
              Giorni festivi: dalle ore 7,30 alle ore 12,30<br></br>     
              dalle ore 16,30 alle ore 18,30<br></br>

            </div>

          <hr></hr>

            <b>PREGHIERE NEL TEMPIO</b>
            <div>
              ore 8,00 lodi mattutine<br></br>
              
              ore 16,45 Vespri<br></br>
              
              ore 17,00 Santo Rosario e preghiera di impetrazione al Volto Santo per le intenzioni
              dei fedeli<br></br><br></br>
              
              Adorazione Eucaristica<br></br>
              
              Ogni giovedì dalle 12,30 alle 17,30 adorazione silenziosa.<br></br>
              
              Ogni martedì alle ore 18.00<br></br>
              
              Ogni 2° e 4° Mercoledì del mese alle ore 20,00(eccetto il mese di agosto)<br></br>
            </div>

          </Typography>
        </AccordionDetails>
      </Accordion>

    </Container>
  );
};
  
export default Info;